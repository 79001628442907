import React, { useState, useEffect } from "react";
import Navbar from "./component/Navbar";

export default function Datacollection() {
  const [users, setUsers] = useState([]);
  console.log(users);
  const [isLoading, setIsLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState("");
  const [currentPage, setCurrentPage] = useState(2);
  const itemsPerPage = 10;

  const today = new Date();
  const year = today.getFullYear();
  const month = ("0" + (today.getMonth() + 1)).slice(-2);
  const day = ("0" + today.getDate()).slice(-2);
  const formattedDate = year + "-" + month + "-" + day;

  useEffect(() => {
    fetch(
      "https://scintillating-gratitude-production.up.railway.app/api/fetchdata"
    )
      .then((response) => response.json())
      .then((data) => {
        setUsers(data.users);
        setIsLoading(false);
      });
  }, []);

  const filteredUsers = users.filter((user) =>
    user.Names.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const totalPages = Math.ceil(filteredUsers.length / itemsPerPage);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };
  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <>
      <Navbar />
      <div className="container">
        <div className="nav-container">
          <div className="nav">
            <div className="nav-image">
              <div className="logo"></div>
            </div>
          </div>
        </div>
        <div className="search">
          <h5 style={{ fontSize: "30px", marginTop: "20px" }}>
            Today's Date:{formattedDate}
          </h5>
        </div>

        <div className="users-grid">
          <table class="table table-bordered">
            <thead>
              <tr>
                <th scope="col">Application_nos</th>
                <th scope="col">Names</th>
                <th scope="col">date</th>
                <th scope="col">Address1</th>
                <th scope="col">Gender</th>
                <th scope="col">Phonenos</th>
                <th scope="col">Approved</th>
              </tr>
            </thead>
            {users.map((user) => (
              <tbody class="table-group-divider">
                <tr>
                  <th scope="row">{user.Application_nos}</th>
                  <th scope="row">{user.Names}</th>
                  <th scope="row">{user.date}</th>
                  <th scope="row">{user.Address1}</th>
                  <th scope="row">{user.Gender}</th>
                  <th scope="row">{user.Phonenos}</th>
                  <th scope="row" style={{ color: "green" }}>
                    Approved
                  </th>
                </tr>
              </tbody>
            ))}
          </table>
        </div>
        <div className="pagination">
          {Array.from({ length: totalPages }, (_, i) => i + 1).map((page) => (
            <button
              key={page}
              className={
                currentPage === page ? "active pagination2" : "pagination1"
              }
              onClick={() => handlePageChange(page)}
            >
              {page}
            </button>
          ))}
        </div>
      </div>
    </>
  );
}
